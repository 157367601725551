<template>
<router-view></router-view>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
body{
 background: #2B3541;
 padding: 0;
 font-size: 16px;
 color: #FFF;
 font-family: 'Inter', sans-serif;
}
p {
  padding: 0;
  margin: 0;
}
.main {
  /* width: calc(100% - 20px); */
  /* margin: 10px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  min-height: 97lvh;
  margin-bottom: 10px;
}
.head {
  font-size: 2em;
}
.inputs {
  width: calc(100% - 15px);
  height: 100%;
  padding: 5px;
}
.input {
  width: calc(100% - 24px);
  background: rgb(243,92,174);
  background: linear-gradient(90deg, rgba(243, 92, 175, 0.5) 0%, rgba(223,45,141,0.15652173913043477) 100%);
  border: 2px solid #CC006E;
  border-radius: 10px;
  padding: 10px;
  color: #FFF;
  font-size: 0.9em;
  margin-bottom: 5px;
  font-family: 'Inter', sans-serif;
}
.Images {
  display: flex;
  gap: 5px;
  flex-wrap: nowrap;
  align-items: center;
}
.input:focus {
  border: 2px solid #ff0088;
  outline: none;
}
.textarea_input {
  resize: none;
}
.input option {
  background: #2B3541;
}
.select {
  width: 100%;
}
.input_head {
  text-align: start;
  margin-bottom: 5px;
}
.submit_btn {
  border: none;
  background: #CC006E;
  color: #FFF;
  padding: 12px 80px;
  border-radius: 10px;
  font-size: 16px;
  margin-top: 10px;
}
.add_btn {
  border: none;
  background: #CC006E;
  color: #FFF;
  padding: 12px;
  border-radius: 10px;
  font-size: 16px;
  margin-top: -5px;
}
.submit_btn:active {
  opacity: .5;
}
*::-webkit-scrollbar {
    width: 2px;
    height: 2px;
} 
*::-webkit-scrollbar-thumb {
    background: rgb(225,29,135);
    background: radial-gradient(circle, rgba(225,29,135,1) 0%, rgba(37,46,66,1) 100%);
    border-radius: 3px;
}
.textarea_input::-webkit-scrollbar-thumb {
    background: rgb(225,29,135);
    background: radial-gradient(circle, rgba(225,29,135,1) 10%, rgba(37,46,66,0) 100%);
    border-radius: 300px;
}
.checkbox {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  margin: 10px 0;
}
.checkbox label {
  display: flex;
  align-items: center;
  gap: 5px;
}
.loader{
display:flex;
  justify-content:center;
  align-items:center;
  height: calc(100vh - 20px);
  width: 100%;

}
.three-body {
 --uib-size: 35px;
 --uib-speed: 0.8s;
 --uib-color: #e11d87;
 position: relative;
 display: inline-block;
 height: var(--uib-size);
 width: var(--uib-size);
 animation: spin78236 calc(var(--uib-speed) * 2.5) infinite linear;
}

.three-body__dot {
 position: absolute;
 height: 100%;
 width: 30%;
}

.three-body__dot:after {
 content: '';
 position: absolute;
 height: 0%;
 width: 100%;
 padding-bottom: 100%;
 background-color: var(--uib-color);
 border-radius: 50%;
}

.three-body__dot:nth-child(1) {
 bottom: 5%;
 left: 0;
 transform: rotate(60deg);
 transform-origin: 50% 85%;
}

.three-body__dot:nth-child(1)::after {
 bottom: 0;
 left: 0;
 animation: wobble1 var(--uib-speed) infinite ease-in-out;
 animation-delay: calc(var(--uib-speed) * -0.3);
}

.three-body__dot:nth-child(2) {
 bottom: 5%;
 right: 0;
 transform: rotate(-60deg);
 transform-origin: 50% 85%;
}

.three-body__dot:nth-child(2)::after {
 bottom: 0;
 left: 0;
 animation: wobble1 var(--uib-speed) infinite
    calc(var(--uib-speed) * -0.15) ease-in-out;
}

.three-body__dot:nth-child(3) {
 bottom: -5%;
 left: 0;
 transform: translateX(116.666%);
}

.three-body__dot:nth-child(3)::after {
 top: 0;
 left: 0;
 animation: wobble2 var(--uib-speed) infinite ease-in-out;
}

@keyframes spin78236 {
 0% {
  transform: rotate(0deg);
 }

 100% {
  transform: rotate(360deg);
 }
}

@keyframes wobble1 {
 0%,
  100% {
  transform: translateY(0%) scale(1);
  opacity: 1;
 }

 50% {
  transform: translateY(-66%) scale(0.65);
  opacity: 0.8;
 }
}

@keyframes wobble2 {
 0%,
  100% {
  transform: translateY(0%) scale(1);
  opacity: 1;
 }

 50% {
  transform: translateY(66%) scale(0.65);
  opacity: 0.8;
 }
}
.footer {
  margin-top: 20px;
}
.uploadFiles {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-around;
}
.uploadFiles_img {
  max-width: 30%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.uploadFiles_img img {
  width: 100%;
  border-radius: 16px;
}
.disabled {
  opacity: 0.5;
  background: #472a3a;
}
</style>
