import { createStore } from 'vuex'
const store = createStore({
    state() {
        return {
            ApiRoot:'https://moscowjungle.ru',
            Banner: {
                head:'Подать заявку на баннер',
                inputs: [
                    {
                        type: 'input',
                        head: 'Имя',
                        content: '',
                        KeyName: 'name'
                    },
                    {
                        type: 'textarea',
                        head: 'Описание',
                        content: '',
                        KeyName: 'description'
                    },
                    {
                        type: 'date',
                        head: 'Дата начала размещения',
                        content: '',
                        KeyName: 'from'
                    },
                    {
                        type: 'date',
                        head: 'Дата конца размещения',
                        content: '',
                        KeyName: 'to'
                    },
                    {
                        type: 'select',
                        head: 'Тип баннера',
                        content: '',
                        KeyName: 'bannerType',
                        selectList: [
                            'BIG',
                            'SMALL',
                        ]
                    },
                    {
                        type: 'select',
                        head: 'Тип контента баннера',
                        content: '',
                        KeyName: 'target',
                        selectList: [
                            'BROWSER',
                            'PLACE',
                            'EVENT'
                        ]
                    },
                    {
                        type: 'input',
                        head: 'Ссылка',
                        content: '',
                        KeyName: 'url'
                    },
                    {
                        type: 'select',
                        head: 'Заведение/Мероприятие',
                        content: '',
                        KeyName: 'targetId',
                        selectList: [
                            'BROWSER',
                            'PLACE',
                            'EVENT'
                        ]
                    },
                    {
                        type: 'file',
                        head: 'Изображение',
                        content: '',
                        KeyName: 'fileId'
                    },
                ],
                footer: {
                    BtnText: 'Отправить'
                }
            },
            Event: {
                head:'Подать заявку на закреп',
                inputs: [
                    {
                        type: 'input',
                        head: 'Имя',
                        name: ''
                    },
                    // Уточнить
                    {
                        type: 'select',
                        head: 'Категория',
                        categoryId: '',
                        selectList: [
                            'BIG',
                            'SMALL',
                        ]
                    },
                    {
                        type: 'textarea',
                        head: 'Краткое описание',
                        announcement: ''
                    },
                    {
                        type: 'textarea',
                        head: 'Полное описание',
                        description: ''
                    },
                    {
                        type: 'file',
                        head: 'Аватар',
                        content: ''
                    },
                    {
                        type: 'file',
                        head: 'Изображения Мероприятия',
                        content: ''
                    },
                    {
                        type: 'input',
                        head: 'Адрес',
                        address: ''
                    },
                    {
                        type: 'input',
                        head: 'Широта',
                        latitude: ''
                    },
                    {
                        type: 'input',
                        head: 'Долгота',
                        longitude: ''
                    },
                    {
                        type: 'date',
                        head: 'Дата Начала',
                        dateTime: ''
                    },
                    {
                        type: 'date',
                        head: 'Дата Завершения',
                        actualUntil: ''
                    },
                    {
                        type: 'input',
                        head: 'Средний чек',
                        price: ''
                    },
                    {
                        type: 'input',
                        head: 'Ссылка',
                        url: ''
                    },
                    {
                        type: 'input',
                        head: 'Метро',
                        metro: ''
                    },
                ],
                footer: {
                    BtnText: 'Отправить'
                }
            },
            PinPlaces: {
                head:'Подать заявку на закреп',
                inputs: [
                    {
                        type: 'select',
                        head: 'Позиция',
                        position: '',
                        selectList: [
                            '1',
                            '2',
                            '3'
                        ]
                    },
                    // Уточнить
                    {
                        type: 'date',
                        head: 'Дата начала размещения закрепа',
                        from: ''
                    },
                    {
                        type: 'date',
                        head: 'Дата окончания размещения закрепа',
                        to: ''
                    },
                    {
                        type: 'select2',
                        head: 'Название',
                        content: '',
                        selectList: [
                            'первый выбор',
                            'второй выбор',
                            'третий выбор',
                            'четвертый выбор'
                        ]
                    }
                ],
                footer: {
                    BtnText: 'Отправить'
                }
            },
            PinEvents: {
                head:'Подать заявку на закреп',
                inputs: [
                    {
                        type: 'select',
                        head: 'Позиция',
                        position: '',
                        selectList: [
                            '1',
                            '2',
                            '3'
                        ]
                    },
                    // Уточнить
                    {
                        type: 'date',
                        head: 'Дата начала размещения закрепа',
                        from: ''
                    },
                    {
                        type: 'date',
                        head: 'Дата окончания размещения закрепа',
                        to: ''
                    },
                    {
                        type: 'select',
                        head: 'Название',
                        content: '',
                        selectList: [
                            'первый выбор',
                            'второй выбор',
                            'третий выбор',
                            'четвертый выбор'
                        ]
                    }
                ],
                footer: {
                    BtnText: 'Отправить'
                }
            },
            Place: {
                head:'Подать заявку на Добавление заведения',
                inputs: [
                    {
                        type: 'input',
                        head: 'Имя',
                        name: ''
                    },
                    {
                        type: 'input',
                        head: 'Метро',
                        name: ''
                    },
                    {
                        type: 'input',
                        head: 'Рабочие время',
                        workTime: ''
                    },
                    {
                        type: 'input',
                        head: 'Средний чек',
                        averageCheck: ''
                    },
                    {
                        type: 'textarea',
                        head: 'Описание',
                        description: ''
                    },
                    {
                        type: 'file',
                        head: 'Меню',
                        menu: ''
                    },
                    {
                        type: 'file',
                        head: 'Изображения',
                        images: ''
                    },
                    {
                        type: 'input',
                        head: 'Телефон',
                        phone: ''
                    },
                    {
                        type: 'input',
                        head: 'Кронтактное лицо',
                        agentName: ''
                    },
                ],
                footer: {
                    BtnText: 'Отправить'
                }
            },
            Organizers: {
                head:'Подать заявку создание организатора',
                inputs: [
                    {
                        type: 'input',
                        head: 'Имя',
                        name: ''
                    },
                    // Уточнить
                    {
                        type: 'select',
                        head: 'Категория',
                        categoryId: '',
                        selectList: [
                            'BIG',
                            'SMALL',
                        ]
                    },
                    {
                        type: 'textarea',
                        head: 'Краткое описание',
                        announcement: ''
                    },
                    {
                        type: 'textarea',
                        head: 'Полное описание',
                        description: ''
                    },
                    {
                        type: 'file',
                        head: 'Аватар',
                        content: ''
                    },
                    {
                        type: 'file',
                        head: 'Изображения Мероприятия',
                        content: ''
                    },
                    {
                        type: 'input',
                        head: 'Адрес',
                        address: ''
                    },
                    {
                        type: 'input',
                        head: 'Широта',
                        latitude: ''
                    },
                    {
                        type: 'input',
                        head: 'Долгота',
                        longitude: ''
                    },
                    {
                        type: 'date',
                        head: 'Дата Начала',
                        dateTime: ''
                    },
                    {
                        type: 'date',
                        head: 'Дата Завершения',
                        actualUntil: ''
                    },
                    {
                        type: 'input',
                        head: 'Средний чек',
                        price: ''
                    },
                    {
                        type: 'input',
                        head: 'Ссылка',
                        url: ''
                    },
                    {
                        type: 'input',
                        head: 'Метро',
                        metro: ''
                    },
                ],
                footer: {
                    BtnText: 'Отправить'
                }
            },
        }
    },
})

export default store