<template>
<router-view></router-view>
</template>
<script>
export default {
  data(){
    // return{
    //   Add: {
    //     targetId: null,
    //     fileId: null,
    //   }
    // }
  },
  methods:{
  // async AddAppeals() {
  //   await fetch(this.$store.state.ApiRoot + '/api/v1/appeals/banners', {
  //     method: 'post',
  //     headers: {
  //       'Authorization': `Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhZG1pbiIsImlhdCI6MTcwMDAzOTc4NSwiZXhwIjoxNzAwMTI2MTg1fQ.akrxwb9SEebS8eLj_RitZzUU_wvedjwr4HT9v23HrNg`,
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify(this.Add)
  //   })
  // }
  },
  // async mounted() {
  //   await fetch(this.$store.state.ApiRoot + '/api/v1/appeals/banners', {
  //     method: 'post',
  //     // headers: {
  //     //   'Authorization': `Bearer ${this.$store.state.token}`
  //     // },
  //     body: this.Add
  //   })
  // }
}
</script>
