import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/BannersView.vue'
const router = createRouter({
  history: createWebHashHistory(), 
  routes: [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/ok',
    name: 'ok',
    component: () => import('../views/OkPage.vue')
  },
  {
    path: '/Thank_you',
    name: 'Thank_you',
    component: () => import('../views/OkPageTwo.vue')
  },
  {
    path: '/Banners',
    name: 'Banners',
    component: () => import('../views/BannersView.vue'),
    children: [
      // при совпадении пути с шаблоном /user/:id
      // в <router-view> компонента User будет показан UserHome
      { path: 'big',
       component: () => import('../views/BannersBig.vue')
      },
      { path: 'small',
       component: () => import('../views/BannersSmall.vue')
      },

      // ...остальные вложенные маршруты
    ]
  },
  {
    path: '/Event',
    name: 'Event',
    component: () => import('../views/EventView.vue')
  },
  {
    path: '/Organizers',
    name: 'Organizers',
    component: () => import('../views/OrganizersView.vue')
  },
  {
    path: '/PayOk',
    name: 'PayOk',
    component: () => import('../views/PayOk.vue')
  },
  {
    path: '/PayNoOk',
    name: 'PayNoOk',
    component: () => import('../views/PayNoOk.vue')
  },
  {
    path: '/Pins',
    name: 'Pins',
    component: () => import('../views/PinView.vue'),
    children: [
      // при совпадении пути с шаблоном /user/:id
      // в <router-view> компонента User будет показан UserHome
      { path: 'events',
       component: () => import('../views/PinEvents.vue')
      },
      { path: 'places',
       component: () => import('../views/PinPlaces.vue')
      },

      // ...остальные вложенные маршруты
    ]
  },
  {
    path: '/Place',
    name: 'Place',
    component: () => import('../views/PlaceView.vue')
  },
]

})

export default router
